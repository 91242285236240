<template>
  <b-modal id="solution-modal" size="md" no-close-on-backdrop>
    <!-- custom modal title -->
    <template #modal-title>
      <div class="d-flex align-items-center">
        <feather-icon size="20" icon="InfoIcon" class="mr-05" />
        <span class="fs-18">Solusi Perbaikan</span>
      </div>
    </template>
    <!-- form container -->
    <loader-component v-if="is_loading" />
    <b-card no-body class="mb-0">
      <b-card-body>
        <validation-observer ref="scraperRules">
          <b-form>
            <b-form-group>
              <validation-provider #default="{ errors }" name="Scraper Name">
                <label for="problem_classification">
                  Klasifikasi Masalah <span class="text-danger">*</span>
                </label>
                <b-input-group>
                  <b-input-group-prepend is-text style="margin-right: -5px;">
                    <feather-icon icon="FolderIcon" />
                  </b-input-group-prepend>
                  <div class="scraper-dropdown" style="width: 27.1rem;">
                    <Dropdown
                      :options="options.problem_classification"
                      v-on:selected="classificationSelection"
                      :disabled="false"
                      :maxItem="10"
                      placeholder="Pilih Klasifikasi Masalah"
                    />
                  </div>
                </b-input-group>
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Solusi Perbaikan"
                rules="required"
              >
                <label for="problem_classification">
                  Solusi <span class="text-danger">*</span>
                </label>
                <b-form-textarea
                  id="solution"
                  placeholder="Solusi Perbaikan"
                  v-model="solution"
                  rows="8"
                />
                <small class="text-danger" style="font-size: 10px">{{
                  errors[0]
                }}</small>
              </validation-provider>
            </b-form-group>
          </b-form>
        </validation-observer>
      </b-card-body>
    </b-card>
    <!-- custom modal footer -->
    <template #modal-footer>
      <!-- button batal -->
      <b-button
        variant="outline-danger"
        size="sm"
        class="float-right mr-05"
        @click="cancelFixed"
      >
        Batal
      </b-button>
      <!-- button simpan -->
      <b-button
        variant="outline-info"
        size="sm"
        class="float-right"
        :disabled="!problem_classification"
        @click.prevent="setFixed"
      >
        Simpan
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BModal,
  VBModal,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BForm,
  BFormTextarea,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BAlert,
} from "bootstrap-vue";
import Dropdown from "vue-simple-search-dropdown";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import useJwt from "@/auth/jwt/useJwt";
import floating_loader from "@/views/components/floatingLoader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "solutionModal",
  components: {
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BForm,
    BFormTextarea,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BAlert,
    Dropdown,
    "loader-component": floating_loader,
  },
  props: {
    id_scraper: {
      type: String,
      default: function() {
        return null;
      },
    },
  },
  data() {
    return {
      is_loading: false,
      username: this.$store.getters["data/user"].username,
      solution: "",
      problem_classification: null,
      options: {
        problem_classification: [],
      },
      //   validation
      required,
    };
  },
  created() {
    this.getProblemClassification();
  },
  methods: {
    classificationSelection(data) {
      this.problem_classification = data.name;
    },
    getProblemClassification() {
      let params = {
        page: 1,
        item: 1000,
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "problem-classification?" + query;
      useJwt.axiosIns.get(api).then((res) => {
        this.options.problem_classification =
          res.data.problem_classification_data || [];
        this.options.problem_classification = this.options.problem_classification.map(
          (el) => ({
            id: el._id,
            name: el.name,
          })
        );
      });
    },
    setFixed() {
      this.$refs.scraperRules.validate().then((success) => {
        if (success) {
          this.is_loading = true;
          let api =
            process.env.VUE_APP_API_URL +
            "scraper-log/update/" +
            this.id_scraper;
          useJwt.axiosIns
            .put(api, {
              data: {
                status_to_update: "OnProgress",
                status: "Fixed",
                problem_classification: this.problem_classification,
                solution: this.solution,
              },
            })
            .then(() => {
              this.solution = "";
              this.$bvModal.hide("solution-modal");
              this.$parent.getOnProgressData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Data berhasil diupdate`,
                },
              });
            })
            .catch((err) => {
              this.solution = "";
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Gagal",
                  icon: "XCircleIcon",
                  variant: "danger",
                  text: `Data gagal diupdate`,
                },
              });
            })
            .finally(() => {
              this.is_loading = false;
            });
        }
      });
    },
    cancelFixed() {
      this.solution = "";
      this.$bvModal.hide("solution-modal");
    },
  },
  directives: {
    "b-modal": VBModal,
  },
};
</script>
<style>
.modal-title {
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-size: 20px !important;
  font-weight: bold;
}
.scraper-dropdown .dropdown-input {
  border-left: none !important;
  border: 1px #d8d6de solid;
  border-radius: 0 5px 5px 0 !important;
  height: 3rem !important;
  min-width: 100% !important;
  font-size: 14px !important;
  background-color: transparent !important;
}
.scraper-dropdown .dropdown-input:focus {
  outline: none !important;
}
.scraper-dropdown .dropdown-content {
  min-width: 100% !important;
  border-radius: 5px !important;
}
.scraper-dropdown .dropdown-content .dropdown-item {
  font-size: 14px !important;
}
.dark-layout * .scraper-dropdown .dropdown-input {
  border: 1px #484849 solid;
}
.dark-layout * .scraper-dropdown .dropdown-input {
  color: #bfbec3 !important;
}
.dark-layout * .dropdown-content .dropdown-item {
  background-color: rgba(22, 29, 49, 0.9) !important;
  color: rgb(230, 225, 225) !important;
}
.dark-layout * .dropdown-content .dropdown-item:hover {
  color: white !important;
}
</style>
