<template>
  <div id="missingclass-logs-page">
    <b-card no-body class="h-100">
      <!-- header component -->
      <b-card-header>
        <!-- title -->
        <b-card-title class="d-flex align-items-center">
          <i class="bi bi-exclamation-circle"></i>
          <span style="margin-left: 5px"> Missing Class Logs </span>
        </b-card-title>
        <!-- filter component -->
        <div class="d-flex">
          <b-button
            v-if="current_page == 'missingclass'"
            variant="outline-success"
            class="mr-1"
            @click="exportData('MissingClass')"
          >
            <feather-icon icon="FileTextIcon" />
            <span style="margin-left: 2px">Export</span>
          </b-button>
          <!-- date filter -->
          <date-picker-component />
          <!-- tier filter -->
          <select
            v-model="tier"
            class="form-control ml-1"
            style="width: 10rem; height: 3rem"
          >
            <option value="">Semua Tier</option>
            <option value="0">Untier</option>
            <option value="1">Tier 1</option>
            <option value="2">Tier 2</option>
            <option value="3">Tier 3</option>
            <option value="4">Tier 4</option>
          </select>
          <!-- keyword filter -->
          <b-input-group class="input-group-merge ml-1" style="width: 15vw">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input
              placeholder="Masukkan kata kunci..."
              v-model="name"
              style="height: 3rem"
            />
          </b-input-group>
        </div>
      </b-card-header>
      <!-- body component -->
      <b-card-body class="mt-1">
        <!-- tabs component -->
        <b-tabs>
          <!-- missingclass tab -->
          <b-tab active @click="changeButton('missingclass')">
            <!-- tab title -->
            <template #title>
              <i class="bi bi-exclamation-circle"></i>
              <span>Missing Class</span>
            </template>
            <!-- loader component -->
            <loader-component v-if="is_loading" class="mt-5" />
            <!-- missing class table component -->
            <table-component
              v-else
              :data="missingclass_data"
              :viewDetail="viewDetail"
              :onProgress="checkOnProgress"
              :is_row_clicked="true"
            />
          </b-tab>
          <!-- on progress tab -->
          <b-tab @click="changeButton('on_progress')">
            <!-- tab title -->
            <template #title>
              <feather-icon icon="ToolIcon" />
              <span>On Progress</span>
            </template>
            <!-- loader component -->
            <loader-component v-if="is_loading" class="mt-5" />
            <!-- on progress table component -->
            <table-component
              v-else
              :data="on_progress_data"
              :viewDetail="viewDetail"
              :fixedData="fixedData"
              :is_row_clicked="true"
            />
          </b-tab>
          <!-- fixed tab -->
          <b-tab @click="changeButton('fixed')">
            <!-- tab title -->
            <template #title>
              <i class="bi bi-check-circle"></i>
              <span>Fixed</span>
            </template>
            <!-- loader component -->
            <loader-component v-if="is_loading" class="mt-5" />
            <!-- fixed tab component -->
            <table-component
              v-else
              :data="fixed_data"
              :viewDetail="viewDetail"
              :is_row_clicked="true"
            />
          </b-tab>
        </b-tabs>
      </b-card-body>
    </b-card>
    <!-- solution modal component -->
    <solution-component :id_scraper="id_scraper" />
  </div>
</template>
<script>
import $ from "jquery";
import moment from "moment";
import useJwt from "@/auth/jwt/useJwt";
import dataTable from "@/views/components/dataTable.vue";
import loader from "@/views/components/loader.vue";
import datePicker from "@/views/components/datePicker.vue";
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BButtonGroup,
  BTabs,
  BTab,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import solutionModalVue from "@/views/components/scraper/solutionModal.vue";
import axios from "axios";

export default {
  name: "MissingClassLogs",
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BButtonGroup,
    BTabs,
    BTab,
    "table-component": dataTable,
    "loader-component": loader,
    "date-picker-component": datePicker,
    "solution-component": solutionModalVue,
  },
  data() {
    return {
      current_page: "missingclass",
      is_loading: true,
      from_date: this.$route.query.from_date,
      to_date: this.$route.query.to_date,
      tier: null,
      page: this.$route.query.page ? this.$route.query.page : 1,
      items: this.$route.query.items ? this.$route.query.items : 10,
      tier: "",
      name: null,
      id_scraper: null,
      username: this.$store.getters["data/user"].username,
      missingclass_data: {
        page_info: {
          limit: 10,
          page: 1,
          total: 0,
          _id: null,
        },
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "status_missingclass",
            label: "status",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "7%" },
          },
          {
            key: "_id",
            label: "nama scraper",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "error_message",
            label: "pesan error",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "start",
            label: "tanggal berjalan",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "end",
            label: "tanggal terhenti",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "duration",
            label: "durasi",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "count",
            label: "jumlah berita",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
        ],
        items: [],
      },
      on_progress_data: {
        page_info: {
          limit: 10,
          page: 1,
          total: 0,
          _id: null,
        },
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "status_on_progress",
            label: "status",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "7%" },
          },
          {
            key: "_id",
            label: "nama scraper",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "error_message",
            label: "pesan error",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "username",
            label: "pic",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "start",
            label: "tanggal berjalan",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "end",
            label: "tanggal terhenti",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "duration",
            label: "durasi",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "count",
            label: "jumlah berita",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
        ],
        items: [],
      },
      fixed_data: {
        page_info: {
          limit: 10,
          page: 1,
          total: 0,
          _id: null,
        },
        fields: [
          {
            key: "no",
            label: "no",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "1rem" },
          },
          {
            key: "status_fixed",
            label: "status",
            thClass: "text-center",
            tdClass: "text-center",
            thStyle: { width: "7%" },
          },
          {
            key: "_id",
            label: "nama scraper",
            sortable: true,
            thClass: "text-left",
            tdClass: "text-left",
          },
          {
            key: "problem_classification",
            label: "klasifikasi masalah",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "error_message",
            label: "pesan error",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "username",
            label: "pic",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "solution",
            label: "solusi",
            thClass: "text-justify",
            tdClass: "text-justify",
          },
          {
            key: "start",
            label: "tanggal berjalan",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "end",
            label: "tanggal terhenti",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "duration",
            label: "durasi",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "count",
            label: "jumlah berita",
            sortable: true,
            thClass: "text-center",
            tdClass: "text-center",
          },
        ],
        items: [],
      },
      cancelTokenMissingClass: null,
      cancelTokenOnProgress: null,
      cancelTokenFixed: null,
    };
  },
  created() {
    if (this.current_page == "missingclass") {
      this.getMissingClassData();
    } else if (this.current_page == "on_progress") {
      this.getOnProgressData();
    } else {
      this.getFixedData();
    }
  },
  watch: {
    "$route.query"() {
      this.page = this.$route.query.page ? this.$route.query.page : 1;
      this.items = this.$route.query.items ? this.$route.query.items : 10;
      this.from_date = this.$route.query.from_date;
      this.to_date = this.$route.query.to_date;
      if (this.current_page == "missingclass") {
        this.getMissingClassData();
      } else if (this.current_page == "on_progress") {
        this.getOnProgressData();
      } else {
        this.getFixedData();
      }
    },
    name() {
      if (this.name.length == 0 || this.name.length > 2) {
        this.updateQuery("page", 1);
        if (this.current_page == "missingclass") {
          this.getMissingClassData();
        } else if (this.current_page == "on_progress") {
          this.getOnProgressData();
        } else {
          this.getFixedData();
        }
      }
    },
    tier() {
      this.updateQuery("page", 1);
      if (this.current_page == "missingclass") {
        this.getMissingClassData();
      } else if (this.current_page == "on_progress") {
        this.getOnProgressData();
      } else {
        this.getFixedData();
      }
    },
  },
  methods: {
    changeButton(status) {
      if (status == "missingclass") {
        this.page = 1;
        this.updateQuery("page", this.page);
        this.getMissingClassData();
      } else if (status == "on_progress") {
        this.page = 1;
        this.updateQuery("page", this.page);
        this.getOnProgressData();
      }
      if (status == "fixed") {
        this.page = 1;
        this.updateQuery("page", this.page);
        this.getFixedData();
      }
      this.current_page = status;
    },
    exportData(status) {
      const params = {
        from_date: this.from_date,
        to_date: this.to_date,
        status: status,
        ...(this.tier ? { tier: this.tier } : {}),
        ...(this.name ? { name: this.name } : {}),
      };
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      const url = `${process.env.VUE_APP_API_URL}scraper-log/export?${query}`;
      window.open(url);
    },
    getMissingClassData() {
      this.is_loading = true;
      if (this.cancelTokenMissingClass) {
        this.cancelTokenMissingClass.cancel();
      }
      this.cancelTokenMissingClass = axios.CancelToken.source();
      let params = {
        from_date: this.$store.getters["data/fromDate"],
        to_date: this.$store.getters["data/toDate"],
        status: "MissingClass",
        page: this.page,
        items: this.items,
        ...(this.name ? { name: this.name } : {}),
      };
      if (this.tier != "") {
        params.tier = this.tier;
      }
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "scraper-log?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancelTokenMissingClass.token })
        .then((res) => {
          if (res.status == 200 && res.data.log_scraper.length > 0) {
            this.missingclass_data.items = res.data.log_scraper;
            this.missingclass_data.page_info = res.data.page_info[0];
          } else {
            this.missingclass_data.items = [];
            this.missingclass_data.page_info = {
              limit: 10,
              page: 1,
              total: 0,
              _id: null,
            };
            this.is_loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (this.missingclass_data.items.length > 0) {
            this.is_loading = false;
          }
        });
    },
    getFixedData() {
      this.is_loading = true;
      if (this.cancelTokenFixed) {
        this.cancelTokenFixed.cancel();
      }
      this.cancelTokenFixed = axios.CancelToken.source();
      let params = {
        from_date: this.$store.getters["data/fromDate"],
        to_date: this.$store.getters["data/toDate"],
        status: "Fixed",
        page: this.page,
        items: this.items,
        ...(this.name ? { name: this.name } : {}),
      };
      if (this.tier != "") {
        params.tier = this.tier;
      }
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "scraper-log?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancelTokenFixed.token })
        .then((res) => {
          if (res.status == 200 && res.data.log_scraper.length > 0) {
            this.fixed_data.items = res.data.log_scraper;
            this.fixed_data.page_info = res.data.page_info[0];
          } else {
            this.fixed_data.items = [];
            this.fixed_data.page_info = {
              limit: 10,
              page: 1,
              total: 0,
              _id: null,
            };
            this.is_loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (this.fixed_data.items.length > 0) {
            this.is_loading = false;
          }
        });
    },
    getOnProgressData() {
      this.is_loading = true;
      if (this.cancelTokenOnProgress) {
        this.cancelTokenOnProgress.cancel();
      }
      this.cancelTokenOnProgress = axios.CancelToken.source();
      let params = {
        from_date: this.$store.getters["data/fromDate"],
        to_date: this.$store.getters["data/toDate"],
        status: "OnProgress",
        page: this.page,
        items: this.items,
        ...(this.name ? { name: this.name } : {}),
      };
      if (this.tier != "") {
        params.tier = this.tier;
      }
      let query = Object.keys(params)
        .map((key) => key + "=" + params[key])
        .join("&");
      let api = process.env.VUE_APP_API_URL + "scraper-log?" + query;
      useJwt.axiosIns
        .get(api, { cancelToken: this.cancelTokenOnProgress.token })
        .then((res) => {
          if (res.status == 200 && res.data.log_scraper.length > 0) {
            this.on_progress_data.items = res.data.log_scraper;
            this.on_progress_data.page_info = res.data.page_info[0];
          } else {
            this.on_progress_data.items = [];
            this.on_progress_data.page_info = {
              limit: 10,
              page: 1,
              total: 0,
              _id: null,
            };
            this.is_loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          if (this.on_progress_data.items.length > 0) {
            this.is_loading = false;
          }
        });
    },
    viewDetail(id) {
      this.$router.push({
        name: "detail-logs",
        query: { scraper: id, status: "Missing Class" },
      });
    },
    checkOnProgress(id) {
      const data = this.missingclass_data.items.find((el) => el._id == id);
      if (data) {
        const params = {
          start_date: moment(data.end).format("YYYY-MM-DD HH:mm:ss"),
          end_date: moment(data.start).format("YYYY-MM-DD HH:mm:ss"),
        };
        const query = Object.keys(params)
          .map((key) => key + "=" + params[key])
          .join("&");
        let api = `${process.env.VUE_APP_API_URL}scraper-log/check-onprogress/${id}?${query}`;
        useJwt.axiosIns.get(api).then((res) => {
          if (res.data.status) {
            this.onProgress(id);
          } else {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Perhatian",
                icon: "CheckCircleIcon",
                variant: "warning",
                text: `Scraper sedang diperbaiki user lain`,
              },
            });
          }
        });
      }
    },
    onProgress(id) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: `<strong>${id}</strong> sedang on progress`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, On Progress",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          let api = process.env.VUE_APP_API_URL + "scraper-log/update/" + id;
          useJwt.axiosIns
            .put(api, {
              data: {
                status_to_update: "MissingClass",
                status: "OnProgress",
                username: this.username,
                solution: "-",
              },
            })
            .then(() => {
              this.getMissingClassData();
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Berhasil",
                  icon: "CheckCircleIcon",
                  variant: "success",
                  text: `Data telah diupdate`,
                },
              });
            })
            .catch(() => {
              this.$toast({
                component: ToastificationContent,
                position: "top-right",
                props: {
                  title: "Gagal !",
                  icon: "AlertCircleIcon",
                  variant: "danger",
                  text: `Data gagal diupdate`,
                },
              });
            });
        }
      });
    },
    fixedData(id) {
      this.$swal({
        title: "Apakah Anda Yakin?",
        html: `<strong>${id}</strong> telah diperbaiki`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya, Telah Diperbaiki",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-info",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.id_scraper = id;
          this.$bvModal.show("solution-modal");
        }
      });
    },
    updateQuery(key, value) {
      let queries = JSON.parse(JSON.stringify(this.$route.query));
      queries[key] = value;
      if ($.param(this.$route.query) != $.param(queries)) {
        this.$router.replace({ query: queries });
      }
    },
  },
};
</script>
